.pcmap {
  .pcmap-route__end {
    background-color: #000000;
  }
  .pcmap-route__switcher {
    background-color: #333333;
  }

  .pcmap-vendor-label {
    display: none;
  }
  .pcmap-pin {
    display: none;
  }
  .pcmap-marker-gate_area {
    .pcmap-marker-title {
      padding: 1.5px 3px;
      background-color: #3c3c32;
      color: white;
      border-radius: 6px;
    }

    .pcmap-marker-icon {
      display: none;
    }
    &.pcmap-marker-selected {
      font-size: 3em;
    }
  }
  .pcmap-marker-b_gates {
    .pcmap-marker-title {
      padding: 1.5px 3px;
      background-color: #3c3c32;
      color: white;
      border-radius: 6px;
    }

    .pcmap-marker-icon {
      display: none;
    }
    &.pcmap-marker-selected {
      font-size: 3em;
    }
  }
  .pcmap-marker-d_gates {
    .pcmap-marker-title {
      padding: 1.5px 3px;
      background-color: #3c3c32;
      color: white;
      border-radius: 6px;
    }

    .pcmap-marker-icon {
      display: none;
    }
    &.pcmap-marker-selected {
      font-size: 3em;
    }
  }
  .pcmap-marker-e_gates {
    .pcmap-marker-title {
      padding: 1.5px 3px;
      background-color: #3c3c32;
      color: white;
      border-radius: 6px;
    }

    .pcmap-marker-icon {
      display: none;
    }
    &.pcmap-marker-selected {
      font-size: 3em;
    }
  }
  .pcmap-marker-pier {
    .pcmap-marker-title {
      padding: 2px 3px;
      background-color: #3c3c32;
      color: white;
      border-radius: 6px;
    }
  }
  .pcmap-marker-area {
    .pcmap-marker-title {
      padding: 2px 3px;
      background-color: #3c3c32;
      color: white;
      border-radius: 6px;
    }

    .pcmap-marker-icon {
      display: none;
    }
  }
  .pcmap-home-button {
    background-image: url(./images/original_view_button.png);
  }

  .pcmap-route-arrow {
    font-family: "fa-solid";
  }
}
